<template>
  <div>
    <div>
      <add-button size="mini" role="system:perm:add" @click.native="toEdit(null)"></add-button>
    </div>
    <el-table :data="dataList" style="width: 100%">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="name" label="权限名称"></el-table-column>
      <el-table-column prop="perm" label="权限标识"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <edit-button type="text" role="system:perm:update" @click.native="toEdit(scope.row)"></edit-button>
          <del-button type="text" role="system:perm:delete" @click.native="toDel(scope.row.id)"></del-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-top-20">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        :page-sizes="[10, 50, 100, 200]"
        @size-change="changeSize"
        :page-size="params.limit"
        :current-page.sync="params.page"
        :total="totalCount"
        @current-change="pageChange">
      </el-pagination>
    </div>
    <el-dialog
      width="30%"
      :title="formData.id ? '编辑' : '新增'"
      :visible.sync="showEditDialog"
      append-to-body>
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="权限名称">
          <el-input v-model="formData.name" maxlength="25" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="权限标识">
          <el-input v-model="formData.perm" maxlength="25" show-word-limit></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">保存</el-button>
          <el-button @click="closeEditDialog">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "editDialog",
  data() {
    return {
      formData: {
        id:'',
        method: "*",
        moduleId:'',
        name: '',
        perm: '',
        // roleIds: null,
        type: 2
      },

      showEditDialog:false,

      isShow: false,

      dataList:[],

      totalCount:0,

      params:{
        page:1,
        limit:10,
        queryMode:'page',
        moduleId:'',
        type:2
      }
    }
  },
  props: {
    path: {
      type: String,
      default: ''
    },
    datas: {
      type: [Array,Object],
      default(){
        return {}
      }
    },
  },
  created() {
    this.params.moduleId = this.datas.id;
    this.formData.moduleId = this.datas.id;
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let _this = this;

      if(this.params.moduleId) {
        this.$http.request({
          url: '/api-admin/api.admin/v1/permissions',
          method: 'get',
          datas: this.params,
          success(res) {
            _this.dataList = res.records;
            _this.totalCount = res.total;
          }
        })
      }
    },

    toEdit(item){
      this.showEditDialog = true;

      this.formData.id = item && item.id ? item.id : '';
      // this.formData.moduleId = item.moduleId;
      this.formData.name = item && item.name ? item.name : '';
      this.formData.perm = item && item.perm ? item.perm : '';
    },

    toDel(id){
      let _this = this;

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
          this.$http.request({
            url: '/api-admin/api.admin/v1/permissions/'+id,
            method: 'delete',
            success(res) {
              _this.$utils.success("删除成功！");
              _this.getList();
            }
          });
        }).catch(() => {});
    },

    pageChange(page){
      this.params.page = page;

      this.getList();
    },

    changeSize(e){
      this.params.page = 1;
      this.params.limit = e;

      this.getList();
    },

    //表单提交
    submitForm(formName) {
      let _this = this;

      let _datas = this.$utils.deepCopy(_this.formData);

      let _id = _datas.id;

      if(!_datas.id){
        delete _datas.id;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.$http.request({
            url:'/api-admin/api.admin/v1/permissions/'+_id,
            method: _datas.id ? 'put' : 'post',
            datas:_datas,
            success(res){
              _this.$message.success('保存成功');

              _this.closeEditDialog();

              _this.getList();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //获取详情
    getDetail() {

    },

    closeEditDialog(){
      this.showEditDialog = false;

      this.formData.id = '';
      this.formData.perm = '';
      this.formData.name = '';
    },
  },
  watch: {
    datas(n, o) {
      this.params.moduleId = n.id;
      this.formData.moduleId = n.id;
      this.getList();
    }
  }
}
</script>
